
.category-item {
  border-radius: 1*$unitMult+$unit;
}


.category-item {
  svg {
    max-height: 50%;
  }
}


.start .banner {
  background-image: url("../../images/client/station.jpg");
  background-position: center;
  background-size: cover;
}

body {
  position: fixed
}
